import { ReactNode } from 'react'
import { ClassNameValue } from 'tailwind-merge'
import Icon from '@shared/components/Icon'
import {
  VerticalBarColors,
  VerticalRoundedBar,
} from '@shared/components/VerticalRoundedBar'
import { tw, twx } from '@shared/utils/tailwind'

export function SummarySectionTitle({ title }: { title: string }) {
  return (
    title && (
      <div
        className={tw`text-xs font-semibold uppercase leading-3 text-gray-07`}
      >
        {title}
      </div>
    )
  )
}

export function SummarySectionDetails({
  bgColor,
  children,
  className,
  barColor,
}: {
  children: ReactNode
  bgColor?: string
  className?: ClassNameValue
  barColor?: VerticalBarColors
}) {
  return (
    <div
      className={tw`flex flex-row justify-start gap-layout-gap-xs rounded-r-lg`}
      // TO-DO covert color code to class
      // eslint-disable-next-line no-restricted-syntax
      style={bgColor ? { backgroundColor: bgColor } : undefined}
    >
      {barColor && <VerticalRoundedBar color={barColor} />}
      <div className={twx('text-sm', className)}>{children}</div>
    </div>
  )
}

export function SummarySection({
  title,
  children,
}: {
  title: string
  children: ReactNode
}) {
  return (
    <div className={twx('flex flex-col gap-layout-gap-sm')}>
      <SummarySectionTitle title={title} />
      {children}
    </div>
  )
}

export function MultiDetailsSummarySection({
  title,
  children,
  className,
}: {
  title: string
  children: ReactNode
  className?: ClassNameValue
}) {
  return (
    <div className={twx('flex flex-col gap-layout-gap-sm')}>
      <SummarySectionTitle title={title} />
      <ul className={twx('flex flex-col flex-wrap gap-layout-gap', className)}>
        {children}
      </ul>
    </div>
  )
}

export function SummarySectionList({
  title,
  children,
  bgColor,
  barColor,
}: {
  title: string
  children: React.ReactNode
  bgColor?: string
  barColor?: VerticalBarColors
}) {
  return (
    <div className={twx('flex flex-col gap-layout-gap-sm')}>
      <SummarySectionTitle title={title} />
      <SummarySectionDetails bgColor={bgColor} barColor={barColor}>
        <ul className={tw`flex flex-col`}>{children}</ul>
      </SummarySectionDetails>
    </div>
  )
}

export function SummarySectionListItem({
  iconName,
  iconClass,
  liClass,
  children,
  onLiClick,
  barColor,
  leftSlot,
  itemsCount,
  idx,
  innerVerticalPaddingClassName,
}: {
  iconName: string
  iconClass: string
  children: React.ReactNode
  itemsCount: number
  idx: number
  liClass?: string
  onLiClick?: () => void
  barColor?: VerticalBarColors
  leftSlot?: JSX.Element
  innerVerticalPaddingClassName?: string
}) {
  const roundedTop = itemsCount === 1 || idx === 0
  const roundedBottom = itemsCount === 1 || idx === itemsCount - 1

  return (
    <li
      className={twx(
        'relative flex flex-row gap-layout-gap-xs leading-relaxed',
        { 'cursor-pointer': onLiClick },
        liClass
      )}
    >
      <div className={tw`absolute -left-6 top-layout-gap-xxs`}>{leftSlot}</div>

      {barColor && (
        <VerticalRoundedBar
          color={barColor}
          roundedTop={roundedTop}
          roundedBottom={roundedBottom}
        />
      )}

      <div
        className={twx(
          'flex gap-layout-gap-xs',
          innerVerticalPaddingClassName ?? 'py-layout-gap-xxs'
        )}
        onClick={onLiClick}
      >
        <Icon
          name={iconName}
          className={twx('fa-fw leading-relaxed', iconClass)}
        />
        {children}
      </div>
    </li>
  )
}
