import { EncryptionMethod } from '@augusthealth/models/com/august/protos/encryption'
import { HL7MessageType } from '@augusthealth/models/com/august/protos/pharmacy_facility'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import { PharmacyFacility } from '@shared/types/pharmacy_facility'
import { PharmacyPartner } from '@shared/types/pharmacy_partner'
import { validStringOrNull } from '@shared/utils/parsing'
import { getReadablePharmacyPartnerName } from '@shared/utils/pharmacyPartner'

export type IntegrationsForm = {
  partner: PharmacyPartner | null
  externalIdentifier: string
  augustFacilityId: string
  facilityPassword?: string
  facilityId?: string
  pharmacyId?: string | null
  supportedOutboundMessageTypes?: HL7MessageType[]
  augustApplicationId?: string | null
  pharmacyApplicationId?: string | null
  //Omnicare
  pharmacyMailboxId: string
  facilityMailboxId: string
  omnicarePharmacyId: string
  omnicareFacilityId: string
  //FRAMEWORK_LTC
  authUserId: string
  nursingStationId: string | null
  frameworkLtcClientConfig?: {
    wsdlUrl: string
    userId: string
    password?: string
  }
  //RedSailQS1
  redsailQs1ClientConfig?: {
    ipAddress: string
    port: number
  }
}

const ENCRYPTED_PASSWORD_DISPLAY = '**********'
export const BLANK_INTEGRATIONS_FORM: IntegrationsForm = {
  partner: null,
  externalIdentifier: '',
  augustFacilityId: '',
  facilityPassword: undefined,
  facilityId: undefined,
  pharmacyId: undefined,
  supportedOutboundMessageTypes: [],
  augustApplicationId: undefined,
  pharmacyApplicationId: undefined,
  //Omnicare
  pharmacyMailboxId: '',
  facilityMailboxId: '',
  omnicarePharmacyId: '',
  omnicareFacilityId: '',
  //FRAMEWORK_LTC
  authUserId: '',
  nursingStationId: null,
  frameworkLtcClientConfig: undefined,
  //RedSailQS1
  redsailQs1ClientConfig: undefined,
}

export const relevantPharmacyPartners = Object.values(PharmacyPartner).filter(
  (partner) =>
    partner !== PharmacyPartner.UNRECOGNIZED &&
    partner !== PharmacyPartner.PHARMACY_PARTNER_UNSPECIFIED
)

export const getPharmacyPartnerOptions =
  (): OptionTypeBase<PharmacyPartner>[] => {
    return relevantPharmacyPartners.map((partner) => {
      return {
        label: getReadablePharmacyPartnerName(partner),
        value: partner,
      }
    })
  }

export const getExistingPharmacyPartnerOptions = (
  integrations?: PharmacyFacility[]
): OptionTypeBase<PharmacyFacility>[] => {
  if (integrations) {
    return integrations.map((integration) => ({
      label: getReadablePharmacyPartnerName(integration.partner!),
      value: integration,
    }))
  }
  return []
}

export const mapPharmacyFacilityToIntegrationsForm = (
  pharmacyFacility: PharmacyFacility
): IntegrationsForm => {
  switch (pharmacyFacility.partner) {
    case PharmacyPartner.PHARMACY_PARTNER_OMNICARE: {
      return {
        ...BLANK_INTEGRATIONS_FORM,
        partner: PharmacyPartner.PHARMACY_PARTNER_OMNICARE,
        externalIdentifier: pharmacyFacility.externalIdentifier,
        facilityPassword: ENCRYPTED_PASSWORD_DISPLAY,
        augustFacilityId: pharmacyFacility.facilityId,
        pharmacyId:
          pharmacyFacility.partnerSpecificConfiguration.omnicare?.facilityId ||
          null,
        omnicarePharmacyId:
          pharmacyFacility.partnerSpecificConfiguration.omnicare?.pharmacyId ||
          '',
        omnicareFacilityId:
          pharmacyFacility.partnerSpecificConfiguration.omnicare?.facilityId ||
          '',
        facilityMailboxId:
          pharmacyFacility.partnerSpecificConfiguration.omnicare
            ?.facilityMailboxId || '',
        pharmacyMailboxId:
          pharmacyFacility.partnerSpecificConfiguration.omnicare
            ?.pharmacyMailboxId || '',
        augustApplicationId: pharmacyFacility.augustApplicationId,
        pharmacyApplicationId: pharmacyFacility.pharmacyApplicationId,
        supportedOutboundMessageTypes:
          pharmacyFacility.supportedOutboundMessageTypes,
      }
    }
    case PharmacyPartner.PHARMACY_PARTNER_FRAMEWORK_LTC: {
      return {
        ...BLANK_INTEGRATIONS_FORM,
        pharmacyId: pharmacyFacility.pharmacyId,
        partner: PharmacyPartner.PHARMACY_PARTNER_FRAMEWORK_LTC,
        augustFacilityId: pharmacyFacility.facilityId,
        externalIdentifier: pharmacyFacility.externalIdentifier,
        facilityPassword: ENCRYPTED_PASSWORD_DISPLAY,
        authUserId:
          pharmacyFacility.partnerSpecificConfiguration.frameworkLtc
            ?.authUserId || '',
        frameworkLtcClientConfig: {
          userId:
            pharmacyFacility.partnerSpecificConfiguration.frameworkLtc
              ?.clientConfig?.userId || '',
          wsdlUrl:
            pharmacyFacility.partnerSpecificConfiguration.frameworkLtc
              ?.clientConfig?.wsdlUrl || '',
          password: pharmacyFacility.partnerSpecificConfiguration.frameworkLtc
            ?.clientConfig?.password?.encryptedValue
            ? ENCRYPTED_PASSWORD_DISPLAY
            : undefined,
        },
        nursingStationId: validStringOrNull(
          pharmacyFacility.partnerSpecificConfiguration.frameworkLtc
            ?.nursingStationId
        ),
        augustApplicationId: pharmacyFacility.augustApplicationId,
        pharmacyApplicationId: pharmacyFacility.pharmacyApplicationId,
        supportedOutboundMessageTypes:
          pharmacyFacility.supportedOutboundMessageTypes,
      }
    }
    case PharmacyPartner.PHARMACY_PARTNER_REDSAIL_QS1:
      return {
        ...BLANK_INTEGRATIONS_FORM,
        pharmacyId: pharmacyFacility.pharmacyId,
        partner: pharmacyFacility.partner,
        augustFacilityId: pharmacyFacility.facilityId,
        externalIdentifier: pharmacyFacility.externalIdentifier,
        ...(pharmacyFacility.partnerSpecificConfiguration.redsailQs1
          ?.clientConfig?.port &&
          pharmacyFacility.partnerSpecificConfiguration.redsailQs1?.clientConfig
            ?.ipAddress && {
            redsailQs1ClientConfig: {
              ipAddress:
                pharmacyFacility.partnerSpecificConfiguration.redsailQs1
                  ?.clientConfig?.ipAddress,
              port: pharmacyFacility.partnerSpecificConfiguration.redsailQs1
                ?.clientConfig?.port,
            },
          }),
        augustApplicationId: pharmacyFacility.augustApplicationId,
        pharmacyApplicationId: pharmacyFacility.pharmacyApplicationId,
        supportedOutboundMessageTypes:
          pharmacyFacility.supportedOutboundMessageTypes,
      }
    case PharmacyPartner.PHARMACY_PARTNER_SUITE_RX:
    case PharmacyPartner.PHARMACY_PARTNER_DIGITAL_RX:
    case PharmacyPartner.PHARMACY_PARTNER_PHARMERICA: {
      return {
        ...BLANK_INTEGRATIONS_FORM,
        pharmacyId: pharmacyFacility.pharmacyId,
        partner: pharmacyFacility.partner,
        augustFacilityId: pharmacyFacility.facilityId,
        externalIdentifier: pharmacyFacility.externalIdentifier,
        augustApplicationId: pharmacyFacility.augustApplicationId,
        pharmacyApplicationId: pharmacyFacility.pharmacyApplicationId,
        supportedOutboundMessageTypes:
          pharmacyFacility.supportedOutboundMessageTypes,
      }
    }
    default: {
      return BLANK_INTEGRATIONS_FORM
    }
  }
}

export const getCreatePharmacyIntegrationPayload = (
  formData: IntegrationsForm
): PharmacyFacility => {
  switch (formData.partner) {
    case PharmacyPartner.PHARMACY_PARTNER_OMNICARE: {
      return {
        partner: formData.partner,
        externalIdentifier: formData.externalIdentifier,
        facilityId: formData.augustFacilityId,
        facilityPassword: {
          method: EncryptionMethod.ENCRYPTION_METHOD_KMS,
          unencryptedPlainText: formData.facilityPassword,
          encryptedValue: null,
        },
        isActive: true,
        augustApplicationId: validStringOrNull(formData.augustApplicationId),
        pharmacyApplicationId: validStringOrNull(
          formData.pharmacyApplicationId
        ),
        pharmacyId: validStringOrNull(formData.pharmacyId),
        supportedOutboundMessageTypes: formData.supportedOutboundMessageTypes,
        partnerSpecificConfiguration: {
          omnicare: {
            facilityId: formData.omnicareFacilityId,
            facilityMailboxId: formData.facilityMailboxId,
            pharmacyId: formData.omnicarePharmacyId,
            pharmacyMailboxId: formData.pharmacyMailboxId,
          },
        },
      }
    }
    case PharmacyPartner.PHARMACY_PARTNER_FRAMEWORK_LTC: {
      return {
        partner: formData.partner,
        externalIdentifier: validStringOrNull(formData.nursingStationId)
          ? `${formData.nursingStationId}:${formData.externalIdentifier}`
          : formData.externalIdentifier,
        facilityId: formData.augustFacilityId,
        facilityPassword: {
          method: EncryptionMethod.ENCRYPTION_METHOD_KMS,
          unencryptedPlainText: formData.facilityPassword!,
          encryptedValue: null,
        },
        isActive: true,
        augustApplicationId: validStringOrNull(formData.augustApplicationId),
        pharmacyApplicationId: validStringOrNull(
          formData.pharmacyApplicationId
        ),
        pharmacyId: validStringOrNull(formData.pharmacyId),
        supportedOutboundMessageTypes: formData.supportedOutboundMessageTypes,
        partnerSpecificConfiguration: {
          frameworkLtc: {
            authUserId: formData.authUserId,
            ...((formData.frameworkLtcClientConfig?.userId ||
              formData.frameworkLtcClientConfig?.wsdlUrl) && {
              clientConfig: {
                userId: validStringOrNull(
                  formData.frameworkLtcClientConfig.userId
                ),
                wsdlUrl: validStringOrNull(
                  formData.frameworkLtcClientConfig.wsdlUrl
                ),
                password:
                  formData.frameworkLtcClientConfig.password !==
                  ENCRYPTED_PASSWORD_DISPLAY
                    ? {
                        method: EncryptionMethod.ENCRYPTION_METHOD_KMS,
                        unencryptedPlainText:
                          formData.frameworkLtcClientConfig.password,
                        encryptedValue: null,
                      }
                    : undefined,
              },
            }),
            //@ts-expect-error
            nursingStationId: validStringOrNull(formData.nursingStationId),
          },
        },
      }
    }
    case PharmacyPartner.PHARMACY_PARTNER_REDSAIL_QS1:
      return {
        partner: formData.partner,
        externalIdentifier: formData.externalIdentifier,
        facilityId: formData.augustFacilityId,
        isActive: true,
        augustApplicationId: validStringOrNull(formData.augustApplicationId),
        pharmacyApplicationId: validStringOrNull(
          formData.pharmacyApplicationId
        ),
        pharmacyId: validStringOrNull(formData.pharmacyId),
        supportedOutboundMessageTypes: formData.supportedOutboundMessageTypes,
        partnerSpecificConfiguration: {
          ...(formData.redsailQs1ClientConfig?.ipAddress &&
            formData.redsailQs1ClientConfig?.port && {
              redsailQs1: {
                clientConfig: {
                  ipAddress: formData.redsailQs1ClientConfig.ipAddress,
                  port: formData.redsailQs1ClientConfig.port,
                },
              },
            }),
        },
      }
    case PharmacyPartner.PHARMACY_PARTNER_SUITE_RX:
    case PharmacyPartner.PHARMACY_PARTNER_DIGITAL_RX:
    case PharmacyPartner.PHARMACY_PARTNER_PHARMERICA: {
      return {
        partner: formData.partner,
        externalIdentifier: formData.externalIdentifier,
        facilityId: formData.augustFacilityId,
        isActive: true,
        augustApplicationId: validStringOrNull(formData.augustApplicationId),
        pharmacyApplicationId: validStringOrNull(
          formData.pharmacyApplicationId
        ),
        pharmacyId: validStringOrNull(formData.pharmacyId),
        supportedOutboundMessageTypes: formData.supportedOutboundMessageTypes,
      }
    }
    default:
      throw new Error('unhandled pharmacy partner')
  }
}

export const getUpdatePharmacyIntegrationPayload = ({
  formData,
  currentIntegration,
}: {
  formData: IntegrationsForm
  currentIntegration: PharmacyFacility
}): PharmacyFacility => {
  switch (currentIntegration.partner) {
    case PharmacyPartner.PHARMACY_PARTNER_OMNICARE: {
      return {
        ...currentIntegration,
        augustApplicationId: validStringOrNull(formData.augustApplicationId),
        pharmacyApplicationId: validStringOrNull(
          formData.pharmacyApplicationId
        ),
        pharmacyId: validStringOrNull(formData.pharmacyId),
        supportedOutboundMessageTypes: formData.supportedOutboundMessageTypes,
        facilityPassword:
          formData.facilityPassword !== ENCRYPTED_PASSWORD_DISPLAY
            ? {
                method: EncryptionMethod.ENCRYPTION_METHOD_KMS,
                unencryptedPlainText: formData.facilityPassword,
                encryptedValue: null,
              }
            : undefined,
        partnerSpecificConfiguration: {
          omnicare: {
            pharmacyMailboxId: formData.pharmacyMailboxId,
            pharmacyId: formData.omnicarePharmacyId,
            facilityMailboxId: formData.facilityMailboxId,
            facilityId: formData.omnicareFacilityId,
          },
        },
      }
    }
    case PharmacyPartner.PHARMACY_PARTNER_FRAMEWORK_LTC: {
      return {
        ...currentIntegration,
        augustApplicationId: validStringOrNull(formData.augustApplicationId),
        pharmacyApplicationId: validStringOrNull(
          formData.pharmacyApplicationId
        ),
        pharmacyId: validStringOrNull(formData.pharmacyId),
        supportedOutboundMessageTypes: formData.supportedOutboundMessageTypes,
        facilityPassword:
          formData.facilityPassword !== ENCRYPTED_PASSWORD_DISPLAY
            ? {
                method: EncryptionMethod.ENCRYPTION_METHOD_KMS,
                unencryptedPlainText: formData.facilityPassword!,
                encryptedValue: null,
              }
            : undefined,
        partnerSpecificConfiguration: {
          frameworkLtc: {
            authUserId: formData.authUserId,
            ...((formData.frameworkLtcClientConfig?.userId ||
              formData.frameworkLtcClientConfig?.wsdlUrl) && {
              clientConfig: {
                userId: validStringOrNull(
                  formData.frameworkLtcClientConfig.userId
                ),
                wsdlUrl: validStringOrNull(
                  formData.frameworkLtcClientConfig.wsdlUrl
                ),
                password:
                  formData.frameworkLtcClientConfig.password !==
                  ENCRYPTED_PASSWORD_DISPLAY
                    ? {
                        method: EncryptionMethod.ENCRYPTION_METHOD_KMS,
                        unencryptedPlainText:
                          formData.frameworkLtcClientConfig.password,
                        encryptedValue: null,
                      }
                    : undefined,
              },
            }),
            //@ts-expect-error
            nursingStationId: validStringOrNull(formData.nursingStationId),
          },
        },
      }
    }
    case PharmacyPartner.PHARMACY_PARTNER_REDSAIL_QS1: {
      return {
        ...currentIntegration,
        augustApplicationId: validStringOrNull(formData.augustApplicationId),
        pharmacyApplicationId: validStringOrNull(
          formData.pharmacyApplicationId
        ),
        pharmacyId: validStringOrNull(formData.pharmacyId),
        supportedOutboundMessageTypes: formData.supportedOutboundMessageTypes,
        partnerSpecificConfiguration: {
          redsailQs1: {
            clientConfig: {
              ipAddress: formData.redsailQs1ClientConfig!.ipAddress,
              port: formData.redsailQs1ClientConfig!.port,
            },
          },
        },
        facilityPassword: undefined,
      }
    }
    case PharmacyPartner.PHARMACY_PARTNER_SUITE_RX:
    case PharmacyPartner.PHARMACY_PARTNER_DIGITAL_RX:
    case PharmacyPartner.PHARMACY_PARTNER_PHARMERICA: {
      return {
        ...currentIntegration,
        augustApplicationId: validStringOrNull(formData.augustApplicationId),
        pharmacyApplicationId: validStringOrNull(
          formData.pharmacyApplicationId
        ),
        pharmacyId: validStringOrNull(formData.pharmacyId),
        supportedOutboundMessageTypes: formData.supportedOutboundMessageTypes,
        facilityPassword: undefined,
      }
    }
    default:
      throw new Error('unhandled pharmacy partner')
  }
}
