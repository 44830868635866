import { UserSessionLogoutReason } from '@augusthealth/models/com/august/protos/user_session'
import urlcat from 'urlcat'
import { v4 as uuidv4 } from 'uuid'
import {
  encodeQueryParams,
  fetchAnything,
  fetchJson,
  mapToApiError,
  requestJson,
} from '@shared/api/request'
import { ApiResponse } from '@shared/api/response'
import { confirmForgotPasswordUrl, forgotPasswordUrl } from '@shared/api/urls'
import { ServiceUnavailableError } from '@shared/types/api/error_response'
import {
  ForgotPasswordRequest,
  ForgotPasswordResponse,
} from '@shared/types/api/forgot_password'
import {
  CreateSessionResponse,
  MfaChallenge,
  MfaCodeVerification,
  MfaConfiguration,
} from '@shared/types/auth'
import { Group } from '@shared/types/permission'
import { PasswordPolicy } from '@shared/types/settings/security_policy'
import { UserAccount, UserAccountFormData } from '@shared/types/user'
// Cypress blows up if we try the usual import
// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import environment from '../environment'

const BASE_URL = environment.baseUrl
const USER_URL = urlcat(BASE_URL, '/user', {})
const USERS_URL = urlcat(BASE_URL, '/users', {})

let cachedUser: UserAccount

export const apiUserUrl = USER_URL
export async function fetchUser(): Promise<UserAccount> {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!cachedUser) {
    cachedUser = await fetchJson(apiUserUrl).then(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (rsp) => rsp.data as UserAccount
    )
  }

  return cachedUser
}

const noopResponseHandler = (r: Response) => Promise.resolve(r)

export const apiCurrentUserSessionUrl = urlcat(
  BASE_URL,
  '/userSessions/current',
  {}
)
export async function isCurrentSessionValid(): Promise<boolean> {
  // default response handler will log out on a 401, causing an infinite redirect loop
  const response = await fetchAnything(
    apiCurrentUserSessionUrl,
    undefined,
    noopResponseHandler
  )

  if (response.status === 503) {
    throw new ServiceUnavailableError({
      requestId: response.headers.get('X-Request-ID') || undefined,
    })
  }

  return response.ok
}

export async function loginWithUsernameAndPassword(
  username: string,
  password: string
): Promise<CreateSessionResponse> {
  const response = await fetchAnything(
    `${BASE_URL}/userSessions`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        credentials: {
          username,
          password,
        },
      }),
    },
    noopResponseHandler
  )

  if (response.status === 200 || response.status === 409) {
    const apiResponse: ApiResponse<CreateSessionResponse> = Object.freeze(
      await response.json()
    ) as ApiResponse<CreateSessionResponse>
    return apiResponse.data
  } else {
    throw new Error('Invalid username or password')
  }
}

export async function loginWithMagicLink(
  email: string,
  customCode: string
): Promise<string> {
  const response = await fetchAnything(
    `${BASE_URL}/userSessions`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        customAuth: {
          email,
          customCode,
        },
      }),
    },
    // We need to provide a custom response handler here
    // The default one treats a 401 as an unauthorized requests
    // and tries to force a logout
    noopResponseHandler
  )

  if (response.status === 200) {
    const loginResponse: ApiResponse<{ username: string }> = Object.freeze(
      await response.json()
    ) as ApiResponse<{ username: string }>

    return loginResponse.data.username
  }

  throw await mapToApiError(response)
}

export async function endSession(
  reason: UserSessionLogoutReason
): Promise<void> {
  await fetchAnything(
    `${BASE_URL}/userSessions/current?reason=${reason}`,
    {
      method: 'DELETE',
    },
    noopResponseHandler
  ).catch((_) => {
    // no-op
  })
}

export async function confirmForgotPassword({
  userAlias,
  code,
  password,
}: {
  userAlias: string
  code: string
  password: string
}) {
  const response = await fetch(encodeQueryParams(confirmForgotPasswordUrl), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-ID': uuidv4(),
      'X-Client-Version': environment.clientVersion,
    },
    body: JSON.stringify({
      userAlias,
      verificationCode: code,
      newPassword: password,
    }),
  })

  if (response.status >= 400) {
    throw new Error()
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const json = await response.json()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
  return json?.data
}

export async function requestPasswordCode(
  forgotPasswordRequest: ForgotPasswordRequest
): Promise<PasswordPolicy | undefined> {
  const response = await fetch(encodeQueryParams(forgotPasswordUrl), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-ID': uuidv4(),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      'X-Client-Version': environment.clientVersion,
    },
    body: JSON.stringify(forgotPasswordRequest),
  })

  const json = (await response.json()) as { data: ForgotPasswordResponse }
  return json.data.passwordPolicy
}

type ChangeTemporaryPasswordProps = {
  userAlias: string
  currentPassword: string
  newPassword: string
}

type ChangeTemporaryPasswordReturn = Promise<{ meta: { hello: 'OK' } }>

export async function changeTemporaryPassword(
  props: ChangeTemporaryPasswordProps
): ChangeTemporaryPasswordReturn {
  const response = await fetch(`${USERS_URL}/changeTemporaryPassword`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-ID': uuidv4(),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      'X-Client-Version': environment.clientVersion,
    },
    body: JSON.stringify(props),
  })

  if (response.status >= 400) {
    throw new Error()
  }

  return (await response.json()) as ChangeTemporaryPasswordReturn
}

export async function patchUser({
  user,
}: {
  user: Omit<UserAccount, 'isActive' | 'phoneNumber' | 'groups'> & {
    phoneNumber?: string | null
    groups?: Group[]
  }
}): Promise<UserAccount> {
  const {
    createdAt,
    createdBy,
    id,
    lastLoginAt,
    username,
    ...userWithoutReadonly
  } = user
  const res = (await requestJson({
    url: `${USERS_URL}/${id}`,
    body: JSON.stringify(userWithoutReadonly),
    contentType: 'application/merge-patch+json',
    method: 'PATCH',
  })) as { data: UserAccount }

  return res.data
}

export function addNewUser(user: UserAccountFormData) {
  return fetchJson(USERS_URL, {
    method: 'POST',
    body: JSON.stringify(user),
  }) as Promise<{ data: { id: number } }>
}

export async function configureMfa(
  mfaConfig: MfaConfiguration
): Promise<MfaChallenge> {
  const response = await fetchAnything(
    `${BASE_URL}/userSessions/mfa/configure`,
    {
      method: 'POST',
      body: JSON.stringify(mfaConfig),
      headers: {
        'Content-Type': 'application/json',
      },
    },
    noopResponseHandler
  )

  if (response.status === 200) {
    const challenge: ApiResponse<MfaChallenge> = Object.freeze(
      await response.json()
    ) as ApiResponse<MfaChallenge>

    return challenge.data
  }

  throw await mapToApiError(response)
}

export async function verifyMfaCode(codeVerification: MfaCodeVerification) {
  const response = await fetchAnything(
    `${BASE_URL}/userSessions/mfa/verify`,
    {
      method: 'POST',
      body: JSON.stringify(codeVerification),
      headers: {
        'Content-Type': 'application/json',
      },
    },
    noopResponseHandler
  )

  if (response.status === 204) {
    return
  }

  throw await mapToApiError(response)
}

export async function resendMfaCode(
  username: string,
  password: string
): Promise<MfaChallenge> {
  const response = await fetchAnything(
    `${BASE_URL}/userSessions/mfa/resend`,
    {
      method: 'POST',
      body: JSON.stringify({
        credentials: {
          username,
          password,
        },
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    },
    noopResponseHandler
  )

  if (response.status === 200) {
    const challenge: ApiResponse<MfaChallenge> = Object.freeze(
      await response.json()
    ) as ApiResponse<MfaChallenge>

    return challenge.data
  }

  throw await mapToApiError(response)
}
