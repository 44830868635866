import { FieldPath, UseFormReturn } from 'react-hook-form'
import { match } from 'ts-pattern'
import { BasicInputProps } from '@shared/components/BasicInput/BasicInput'
import {
  PharmacyPartner,
  ValidPharmacyPartner,
} from '@shared/types/pharmacy_partner'
import notEmpty from '@shared/utils/notEmpty'
import { IntegrationsForm } from '@app/pages/Tools/PharmacyTools/PharmacyIntegration/helpers'

export type PartnerFormField = Omit<BasicInputProps, 'name'> & {
  name: FieldPath<IntegrationsForm>
}
export type PartnerFormConfig = {
  us: PartnerFormField[]
  them: PartnerFormField[]
  additional: PartnerFormField[]
}
type PartnerToFieldsMode =
  | {
      tag: 'edit'
      formData: IntegrationsForm
    }
  | {
      tag: 'create'
    }
export const getFormConfigurationForPharmacyPartner = (
  methods: UseFormReturn<IntegrationsForm>,
  partner: PharmacyPartner,
  mode: PartnerToFieldsMode
): PartnerFormConfig | null => {
  const isEditing = mode.tag === 'edit'
  const EXTERNAL_IDENTIFIER: PartnerFormField = {
    label: 'External Identifier',
    name: 'externalIdentifier',
    readOnly: isEditing,
    required: true,
  }

  return match(partner as ValidPharmacyPartner)
    .with(PharmacyPartner.PHARMACY_PARTNER_OMNICARE, () => {
      return {
        additional: [
          {
            label: 'August Application ID',
            name: 'augustApplicationId',
          },
          {
            label: 'Pharmacy Application ID',
            name: 'pharmacyApplicationId',
          },
        ],
        us: [],
        them: [
          {
            ...EXTERNAL_IDENTIFIER,
            value: isEditing
              ? mode.formData.externalIdentifier
              : [
                  methods.watch('pharmacyMailboxId') ?? null,
                  methods.watch('omnicareFacilityId') ?? null,
                ]
                  .filter(notEmpty)
                  .join(':'),
            readOnly: true,
          },
          {
            label: 'Facility Password',
            name: 'facilityPassword',
            required: true,
          },
          {
            label: 'Pharmacy Mailbox ID',
            name: 'pharmacyMailboxId',
            required: true,
            readOnly: isEditing,
          },
          {
            label: 'Pharmacy ID',
            name: 'omnicarePharmacyId',
            required: true,
          },
          {
            label: 'Facility Mailbox ID',
            name: 'facilityMailboxId',
            required: true,
          },
          {
            label: 'Facility ID',
            name: 'omnicareFacilityId',
            required: true,
            readOnly: isEditing,
          },
        ],
      } as PartnerFormConfig
    })
    .with(PharmacyPartner.PHARMACY_PARTNER_FRAMEWORK_LTC, () => {
      return {
        additional: [
          {
            label: 'August Application ID',
            name: 'augustApplicationId',
          },
          {
            label: 'Pharmacy Application ID',
            name: 'pharmacyApplicationId',
          },
          {
            label: 'Nursing Station ID',
            name: 'nursingStationId',
            readOnly: isEditing,
          },
          {
            label: 'Pharmacy ID',
            name: 'pharmacyId',
          },
          {
            label: 'Supported Outbound Message Types',
            name: 'supportedOutboundMessageTypes',
          },
          {
            label: 'User ID',
            name: 'frameworkLtcClientConfig.userId',
          },
          {
            label: 'Password',
            name: 'frameworkLtcClientConfig.password',
          },
          {
            label: 'WSDL Url',
            name: 'frameworkLtcClientConfig.wsdlUrl',
          },
        ],
        us: [
          {
            label: 'Facility Password',
            name: 'facilityPassword',
            required: true,
          },
          {
            label: 'Auth User ID',
            name: 'authUserId',
            required: true,
          },
        ],
        them: [EXTERNAL_IDENTIFIER],
      } as PartnerFormConfig
    })
    .with(PharmacyPartner.PHARMACY_PARTNER_REDSAIL_QS1, () => {
      return {
        additional: [
          {
            label: 'August Application ID',
            name: 'augustApplicationId',
          },
          {
            label: 'Pharmacy Application ID',
            name: 'pharmacyApplicationId',
          },
          {
            label: 'Pharmacy ID',
            name: 'pharmacyId',
          },
          {
            label: 'Supported Outbound Message Types',
            name: 'supportedOutboundMessageTypes',
          },
          {
            label: 'Ip Address',
            name: 'redsailQs1ClientConfig.ipAddress',
          },
          {
            label: 'Port',
            name: 'redsailQs1ClientConfig.port',
            type: 'number',
          },
        ],
        us: [],
        them: [EXTERNAL_IDENTIFIER],
      } as PartnerFormConfig
    })
    .with(
      PharmacyPartner.PHARMACY_PARTNER_SUITE_RX,
      PharmacyPartner.PHARMACY_PARTNER_DIGITAL_RX,
      PharmacyPartner.PHARMACY_PARTNER_PHARMERICA,
      () => {
        return {
          additional: [
            {
              label: 'August Application ID',
              name: 'augustApplicationId',
            },
            {
              label: 'Pharmacy Application ID',
              name: 'pharmacyApplicationId',
            },
            {
              label: 'Pharmacy ID',
              name: 'pharmacyId',
            },
          ],
          us: [],
          them: [EXTERNAL_IDENTIFIER],
        } as PartnerFormConfig
      }
    )
    .exhaustive()
}

export const partnerConfigHasNoEditableFields = (config: PartnerFormConfig) => {
  return (
    config.us.length === 0 &&
    config.them.length === 1 &&
    config.additional.length === 0 &&
    config.them[0].name === 'externalIdentifier'
  )
}

export const isFieldRequiredForOutboundMessageTypes = (
  fieldName: PartnerFormField['name'],
  selectedPharmacyPartner: PharmacyPartner
): boolean => {
  if (fieldName === 'pharmacyId') {
    return true
  }

  if (
    selectedPharmacyPartner ===
      PharmacyPartner.PHARMACY_PARTNER_FRAMEWORK_LTC &&
    fieldName.includes('frameworkLtcClientConfig')
  ) {
    return true
  }

  if (
    selectedPharmacyPartner === PharmacyPartner.PHARMACY_PARTNER_REDSAIL_QS1 &&
    fieldName.includes('redsailQs1ClientConfig')
  ) {
    return true
  }

  return false
}
